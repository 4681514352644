<template>
    <b-modal size="lg" title="Add a Member" hide-footer id="clientModal">
        <div class="modal-body" style="width: 100%">
            <clientCreateClientForm :edit="false" />
            <div class="d-flex justify-content-between mt-2">
                <div></div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import clientCreateClientForm from "./clientCreateClientForm.vue";
import { clientMethods, clientComputed } from "@/state/helpers";

export default {
    components: { clientCreateClientForm },
    data: () => ({}),

    computed: {
        ...clientComputed,
    },
    methods: {
        ...clientMethods,
        // closeModal() {
        //     this.$bvModal.hide("clientModal");
        // },
    },
    mounted() {},
};
</script>

<style lang="scss" scoped></style>
